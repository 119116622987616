import "./App.css";
import Section from "./Section";
import Header from "./components/Header";
function App() {
  return (
    <div className="App">
      <Header />
      <Section
        title="Saturn"
        text="Saturn with its beautiful rings"
        bgimg="saturn.jpg"
      />
      <Section
        title="M20 Galaxy"
        text="A beautiful galaxy"
        bgimg="potw2014a.jpg"
      />
      <Section
        title="Nebula"
        text="Nebula with is gases"
        bgimg="potw2119a.jpg"
      />
    </div>
  );
}

export default App;
