import React from "react";
import styled from "styled-components";

function Section({ bgimg, title, text }) {
  return (
    <Container bgimg={bgimg}>
      <TextArea>
        <h1>{title}</h1>
        <p>{text}</p>
      </TextArea>
      <Button>
        <a href="/">Know More</a>
      </Button>
    </Container>
  );
}

export default Section;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-image: ${(props) => `url("/images/${props.bgimg}")`};
  background-position: center;
  background-size: cover;
  h1 {
    font-size: 6vw;
    color: #fff;
  }
  p {
    color: #fff;
    font-size: 2vw;
  }
`;

const TextArea = styled.div`
  text-align: center;
`;

const Button = styled.div`
  margin-top: 10rem;
  text-align: center;
  width: 10rem;
  height: 5rem;
  a{
    padding 6px 10px;
    background-color: red;
    color: white;
    border-radius: 5px;
  }
`;
